import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import "../../assets/descaling.sass"
import Image from "../../components/image"
import Carousel from 'react-bootstrap/Carousel'

const SewerandPlumbing = () => (
  <div className="descaling-component">
    
    <SEO title="Sewer and Plumbing" />

    <div className="service-header">
      <h1>Sewer and Plumbing</h1>
    </div>

    <div className="descaling-statements descaling-statements-red">
      <p>
      Welcome to our premier electrical services,  we specialize in a wide range of electrical solutions tailored to meet your every need. Our expert team is dedicated to providing top-notch service in home interior lighting and electrical wiring, ensuring your living spaces are both functional and aesthetically pleasing. For entertainment enthusiasts, we offer professional TV installations and home theater setups that transform your viewing experience. Our expertise also extends to the outdoors, where we design and install both seasonal and permanent exterior lighting, enhancing the beauty and safety of your property.</p>
    </div>

    <div className="desktop-image-video">

      <Carousel>
            <div className="scoping-images">
              <Image filename="pipe.png"/>
            </div>
      </Carousel>

    </div>
    
    <div className="descaling-statements desktop-statements">    
      <ul className="sewer-services-list">
        <li>Mulching</li>
        <li>Spring, fall, and summer cleanings</li>
        <li>Tree and plant planting</li>
        <li>Landscape lighting</li>
        <li>Retaining walls, patios, patio walkways</li>
        <li>Fencing</li>
        <li>Decking</li>
        <li>Pergolas</li>
        <li>Privacy walls</li>
        <li>Awnings</li>
        <li>Custom pieces</li>
        <li>And more</li>
      </ul>
    </div>

  </div>
)

export default SewerandPlumbing
